import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useIsAuthenticated } from "@azure/msal-react";
import { Bot, Database } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
export function Homepage() {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  if (!isAuthenticated) {
    navigate("/");
  }
  const links = [
    {
      url: "/review-analysis-db",
      title: "Review Analyzer",
      desc: "",
      state: { bot_name: "chat_service" },
      img: <Database className="h-8 w-8"></Database>,
    },
    {
      url: "/results",
      title: "UB AI Assistant",
      state: { bot_name: "chat_service", title: "UB AI ASSISTANT" },
      desc: "",
      img: <Bot className="h-8 w-8"></Bot>,
    },
    {
      url: "/agent",
      title: "UB SQL Agent",
      state: { bot_name: "sql_bot_chat_service", title: "UB SQL Agent" },
      desc: "",
      img: <Bot className="h-8 w-8"></Bot>,
    },
    {
      url: "/results",
      title: "UB CS Assistant",
      state: { bot_name: "customer_chat_service", title: "UB CS ASSISTANT" },
      desc: "Customer Service AI Assistant",
      img: <Bot className="h-8 w-8"></Bot>,
    },
    {
      url: "/results",
      title: "UB Regulatory Creative Assistant",
      desc: "Compliance AI Assistant",
      state: {
        bot_name: "compliance_chat_service",
        title: "UB REGULATORY CREATIVE ASSISTANT",
      },
      img: <Bot className="h-8 w-8"></Bot>,
    },
    {
      url: "/results",
      title: "UB Dev Assistant",
      desc: "UB Dev Assistant",
      state: {
        bot_name: "dev_chat_service",
        title: "UB DEV ASSISTANT",
      },
      img: <Bot className="h-8 w-8"></Bot>,
    },
  ];
  return (
    <div className="container mx-auto px-4 py-8 ">
      <div className="flex flex-wrap justify-center gap-6 ">
        {links.map((tool, index) => (
          <Link
            key={index}
            to={tool.url}
            state={tool.state}
            className="w-full sm:w-[calc(50%-12px)] md:w-[calc(33.333%-16px)] lg:w-[calc(25%-18px)] max-w-sm"
          >
            <Card className="h-[250px] transition-all hover:shadow-lg hover:-translate-y-1 unybrands-bg-vlight text-black">
              <CardHeader>
                <div className="flex items-center gap-4">
                  {tool.icon}
                  <CardTitle>{tool.title}</CardTitle>
                </div>
              </CardHeader>
              <CardContent className="text-black">
                <CardDescription>{tool.desc}</CardDescription>
              </CardContent>
            </Card>
          </Link>
        ))}
      </div>
    </div>
  );
}
