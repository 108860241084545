import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./page/home";
import "./assets/css/style.css";
import ReviewAnalysis from "./page/ReviewAnalysis.js";
import ReviewAnalysisDb from "./page/ReviewAnalysisDb.js";
import TitleGenerator from "./page/TitleGenerator.js";
import Header from "./components/includes/Header";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import AddAsin from "./page/AddAsin.js";
import { LandingPage } from "./page/landing-page";
import { Homepage } from "./page/homepage";
import NewFeatureBullet from "./page/feature-bullet-generator";
import NewCrud from "./page/NewCrud";
import AgentHome from "./page/agent";
import CustomerService from "./page/CustomerService";

function App() {
  const { accounts } = useMsal();
  return (
    <BrowserRouter>
      <div className="h-screen overflow-hidden flex flex-col">
        <Header />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/home"
            element={
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
              >
                <Homepage />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/results"
            element={
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
              >
                {/* <Login></Login> */}
                <Home />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/agent"
            element={
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
              >
                {/* <Login></Login> */}
                <AgentHome />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/customer-service"
            element={
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
              >
                {/* <Login></Login> */}
                <CustomerService />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/feature-bullet-generator"
            element={
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
              >
                <NewFeatureBullet />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/title-generator"
            element={
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
              >
                <TitleGenerator />
              </MsalAuthenticationTemplate>
            }
          />

          <Route
            path="/add-asin"
            element={
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
              >
                <AddAsin />
              </MsalAuthenticationTemplate>
            }
          />

          <Route
            path="/review-analysis"
            element={
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
              >
                <ReviewAnalysis />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/review-analysis-db"
            element={
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
              >
                <ReviewAnalysisDb />
              </MsalAuthenticationTemplate>
            }
          />

          <Route path="/admin" element={<NewCrud />} />
          <Route path="*" element={<p>You are lost</p>} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
