import React, { useState } from "react";
import loaderImg from "../assets/images/loader.gif";
import Footertext from "../components/includes/FooterText";
import Loader from "../components/includes/Loader";
import FormTitle from "../components/includes/FormTitle";
import Select from "../components/core/Select";
import Input from "../components/core/input";
import { useForm } from "react-hook-form";
import TextArea from "../components/core/textArea";
import Button from "../components/core/button";
import { sendRequest } from "../services/apiService";
import ReviewResult from "./ReviewResult";

const ReviewAnalysis = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [response, setResponse] = useState({});
  const [requestData, setRequestData] = useState({});
  const [requestDone, setRequestDone] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, reset, isSubmitting },
  } = useForm({ mode: "onChange" });

  const onSubmit = async (data) => {
    setShowLoader(true);

    const requestBody = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== "")
    );

    //console.log(requestBody)

    const response = await sendRequest(requestBody, "customer_review_analysis");

    if (response.status === 200) {
      setResponse(response.data);
      setRequestData(requestBody);
      setShowLoader(false);
      setRequestDone(true);
      return;
    }

    setShowLoader(false);
    setRequestDone(false);
    setError("asin", { type: "custom", message: "ASIN not found" });
  };

  const CountryList = [
    {
      optionName: "United States",
      optionValue: "US",
    },
    {
      optionName: "United Kingdom",
      optionValue: "UK",
    },
    {
      optionName: "France",
      optionValue: "FR",
    },
    {
      optionName: "Germany",
      optionValue: "DE",
    },
    {
      optionName: "Italy",
      optionValue: "IT",
    },
    {
      optionName: "Australia",
      optionValue: "AU",
    },
    {
      optionName: "Belgium",
      optionValue: "BE",
    },
    {
      optionName: "Brazil",
      optionValue: "BR",
    },
    {
      optionName: "Canada",
      optionValue: "CA",
    },
    {
      optionName: "China",
      optionValue: "CN",
    },
    {
      optionName: "Egypt",
      optionValue: "EG",
    },
    {
      optionName: "India",
      optionValue: "IN",
    },
    {
      optionName: "Japan",
      optionValue: "JP",
    },
    {
      optionName: "Mexico",
      optionValue: "MX",
    },
    {
      optionName: "Netherlands",
      optionValue: "NL",
    },
    {
      optionName: "Poland",
      optionValue: "PL",
    },
    {
      optionName: "Saudi Arabia",
      optionValue: "SA",
    },
    {
      optionName: "Singapore",
      optionValue: "SG",
    },
    {
      optionName: "Spain",
      optionValue: "ES",
    },
    {
      optionName: "Sweden",
      optionValue: "SE",
    },
    {
      optionName: "Turkey",
      optionValue: "TR",
    },
    {
      optionName: "UAE",
      optionValue: "AE",
    },
  ];

  const StarsList = [
    {
      optionName: "All Stars",
      optionValue: "all_stars",
    },
    {
      optionName: "All Negative",
      optionValue: "all_critical",
    },
    {
      optionName: "All Positive",
      optionValue: "all_positive",
    },
    {
      optionName: "Five Star",
      optionValue: "five_star",
    },
    {
      optionName: "Four Star",
      optionValue: "four_star",
    },
    {
      optionName: "Three Star",
      optionValue: "three_star",
    },
    {
      optionName: "Two Star",
      optionValue: "two_star",
    },
    {
      optionName: "One Star",
      optionValue: "one_star",
    },
    // {
    //     optionName: "All Positive",
    //     optionValue: "all_positive",
    // },
    // {
    //     optionName: "All Critical",
    //     optionValue: "all_critical",
    // },
  ];
  return (
    <>
      <div
        className={`container-xxl main__wrapper ${
          !showLoader && !requestDone ? "" : "d-none"
        } `}
        id="generator_form_container"
      >
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-6 col-sm-8">
            <FormTitle
              title="Amazon Review Analyzer"
              subTitle="Fill the necessary field to proceed"
            />
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="form__container mb-4"
            >
              <Input
                inputProps={register("asin", {
                  required: {
                    value: true,
                    message: "Please fill in the ASIN field",
                  },
                  minLength: {
                    value: 10,
                    message: "Please enter a 10-digit ASIN",
                  },
                  maxLength: {
                    value: 10,
                    message: "Please enter a 10-digit ASIN",
                  },
                })}
                Label="Enter ASIN #(Required):"
                placeholder="Enter the exact Amazon ASIN"
                errors={errors}
                inputId="asin"
              />
              <Select
                SelectData={CountryList}
                label="Country (Amazon Domain):"
                selectId="amazon_marketplace"
                inputProps={register("amazon_marketplace")}
              />
              <Select
                SelectData={StarsList}
                label="Filter Comments by All Positive, All Critical or Number of Stars:"
                selectId="review_stars"
                inputProps={register("review_stars")}
              />
              <TextArea
                inputProps={register("prompt")}
                TextAreaId="prompt"
                Label="Prompt/Instructions ...for analysis based on the customer
                reviews (optional):"
                placeholder="Provide additional instructions"
                errors={errors}
              >
                <p
                  className="form-label mb-3"
                  style={{ fontSize: "12px", lineHeight: "1" }}
                >
                  <br />
                  Example prompts inlcude: <br /> 1. List in bullet points the
                  top dissatisfiers based on review content (e.g., product
                  defects, delivery issues). This section should have a title
                  above it that says "Top Dissatisfiers Based on Customer
                  Reviews". 2. Next, in a new section entitled "Top 5
                  Dissatisfiers" create a simple table that shows the exact
                  count of how many times each of the top 5 dissatisfiers
                  appeared in a review. If there are dissatisfiers that are
                  clearly related to the same underlying cause, they should be
                  counted in the same category. For example, for a dog chew
                  product, if there are 10 reviews that mention 'mold' and 5
                  reviews that mention "bad smell", then you should have a row
                  for 'mold/bad smell' with a total count of 15. 3. Next, in a
                  section entitled " Summary of Relevant Review" for each top
                  dissatisfier, summarize three representative reviews, and give
                  the date a title of the review 4. Last, in a section entitled
                  "Insights and Product Improvement Suggestions" add any
                  additional insights and product improvement suggestions based
                  on the analysis
                  <br />
                  <br /> If no specific prompt is given, then you will receive a
                  report with the following information: (A) impression on
                  public, (B) complaints, (C) 3 top positive reviews, (D) 3 top
                  negative reviews, and (E) product improvement suggestions.
                </p>
              </TextArea>
              <Button disabled={isSubmitting} label="Submit" type="submit" />
            </form>
            <Footertext />
          </div>
        </div>
      </div>
      {Object.keys(response).length > 0 && !showLoader && (
        <ReviewResult
          regenerateFunction={() => onSubmit(requestData)}
          asin={requestData.asin}
          response={response}
        />
      )}

      <Loader
        showLoader={showLoader}
        setShowLoader={setShowLoader}
        loaderImg={loaderImg}
      />
    </>
  );
};

export default ReviewAnalysis;
