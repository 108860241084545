import { Button } from "@/components/ui/button";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Input } from "@/components/ui/input";
import { Card } from "@/components/ui/card";
import { useForm } from "react-hook-form";
import { sendRequest } from "../services/apiService";
import { useAccount, useMsal } from "@azure/msal-react";
import {
  MessageList,
  UserMessage,
  AIMessage,
  LastAIMessage,
} from "@/components/core/messagelist";
import { useIsAuthenticated } from "@azure/msal-react";
import { CirclePause, File, Image, Plus, Send, X } from "lucide-react";
import { Constants } from "@/constants";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Spinner as ShadcnSpinner } from "@/components/ui/spinner"; // Adjust the import based on your UI library

const Spinner = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center text-white font-light text-2xl bg-black bg-opacity-60 z-50">
      Creating a new thread . . .
      <ShadcnSpinner size="large" className="text-white " />
    </div>
  );
};
export default function Home() {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await instance.acquireTokenSilent({
          scopes: ["User.Read"],
          account: account,
        });
        // Use response.accessToken for API calls
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup({ scopes: ["User.Read"] }); // Fallback to popup
        }
      }
    };
    console.log(account);
    if (account) fetchToken();
  }, [account, instance]);
  const [message, setMessage] = useState("");
  const [showThreadLoader, setShowThreadLoader] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const formRef = useRef();
  const isAuthenticated = useIsAuthenticated();
  let location = useLocation();
  const [messages, setMessages] = useState([]);
  const [thread_id, setThreadId] = useState("");
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);

  var aiMessage = "";
  async function getThreadId() {
    setShowThreadLoader(true);
    const res = await sendRequest(
      null,
      `thread_id?bot_name=${location.state.bot_name}`,
      "get"
    );
    setThreadId(res.data.thread_id);
    setShowThreadLoader(false);
    return res.data.thread_id;
  }

  function getText(arr) {
    for (const i in arr) {
      let result = JSON.parse(arr[i], { stream: true });
      if (result.type === "text") {
        if (result.text.value) {
          aiMessage += result.text.value;
          setMessage(aiMessage);
        } else {
          for (const key in result.text.annotations) {
            const element = result.text.annotations[key];
            if (element.type == "file_path") {
              aiMessage = aiMessage.replace(
                element.text,
                `${Constants.apiUrl}/files/${element.file_path.file_id}?bot_name=${location.state.bot_name}`
              );
              setMessage(aiMessage);
            }
          }
        }
      } else if (result.type === "image_file") {
        aiMessage += `![Image](${Constants.apiUrl}/files/${result.image_file.file_id}?type=image&bot_name=${location.state.bot_name})`;
        setMessage(aiMessage);
      }
    }
  }
  useEffect(() => {
    if (location.state && location.state.thread_id) {
      setThreadId(location.state.thread_id);
    }

    const finalString = location.state.data;
    if (finalString) {
      setMessages([
        {
          sender: "ai",
          message: finalString,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      const newHeight = Math.min(textarea.scrollHeight, 24 * 10); // 24px is roughly one line height, 6 is max rows
      textarea.style.height = `${newHeight}px`;
    }
  }, [message]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleFileUpload = (type) => {};

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });
  const [files, setFiles] = useState([]);
  const onFileChange = (e) => {
    const files = e.target.files;
    if (files) {
      const newFiles = Array.from(files).map((file) => ({
        id: Date.now() + Math.random(),
        name: file.name,
        type: file.type.startsWith("image/") ? "image" : "file",
        obj: file,
      }));
      setFiles((prev) => [...prev, ...newFiles]);
    }
  };
  useEffect(() => {
    const messagesContainer = messagesEndRef.current?.parentElement;
    if (messagesContainer) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
  }, [messages]);
  const onTextareaKeydown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      formRef.current?.requestSubmit();
    }
  };
  const handleRemoveFile = (id) => {
    setFiles((prev) => prev.filter((file) => file.id !== id));
  };
  const { ref, ...rest } = register("message");
  const { ref: fileRef, ...fileRest } = register("files");

  const onSubmit = async (data, e) => {
    let updatedThreadId = thread_id;
    if (messages.length <= 0 && thread_id.length <= 0) {
      updatedThreadId = await getThreadId();
    }
    setFiles([]);
    e.target.reset();
    setMessage(() => "");
    const messageWithSender = {
      sender: "user",
      message: data.message,
      timestamp: new Date().toISOString(), // Add timestamp here
    };
    setShowLoader(true);
    setMessages((prev) => [...prev, messageWithSender]);
    let params = { message: data.message, bot_name: location.state.bot_name };
    if (files.length > 0) {
      const file = new FormData();
      files.forEach((fileItem) => {
        file.append("files", fileItem.obj, fileItem.name); // Append each file with its name
      });
      file.append("bot_name", location.state.bot_name);
      const resp = await sendRequest(
        file,
        "files",
        "post",
        "multipart/form-data"
      );
      params["other_file_ids"] = resp.data.other_file_ids;
      params["image_ids"] = resp.data.image_ids;
    }
    try {
      const response = await fetch(`${Constants.apiUrl}/bot`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...params,
          thread: updatedThreadId,
        }),
      });

      const reader = response.body.getReader();

      const decoder = new TextDecoder("utf-8");

      while (true) {
        const { done, value } = await reader.read();

        if (done) break;
        const val = decoder.decode(value, { stream: true });
        const arr = val.split("\n--END--\n").filter((ele) => ele);
        getText(arr);
      }
      setShowLoader(false);
      setMessages((prev) => [
        ...prev,
        {
          sender: "ai",
          message: aiMessage,
          timestamp: new Date().toISOString(),
        },
      ]);
      setMessage("");
    } catch {
      setMessages((prev) => [
        ...prev,
        {
          sender: "ai",
          message: aiMessage,
          timestamp: new Date().toISOString(),
        },
      ]);
      setShowLoader(false);
    }
  };
  async function onCancel(e) {
    e.preventDefault();
    await sendRequest(
      null,
      `cancel?thread_id=${thread_id}&bot_name=${location.state.bot_name}`,
      "get"
    );
  }
  return (
    <>
      {" "}
      {showThreadLoader && <Spinner />}
      <div className="flex flex-col unybrands-bg-vlight h-screen overflow-hidden">
        <header className="flex justify-center   py-1">
          <div className=" font-light text-2xl text-blue-500">
            {location.state.title}
          </div>
        </header>
        <MessageList>
          {messages.map((msg) => (
            <div
              key={msg.id}
              className={`flex ${
                msg.sender === "user" ? "justify-end" : "justify-start"
              }`}
            >
              {msg.sender === "user" ? (
                <UserMessage message={msg} />
              ) : (
                <AIMessage message={msg} />
              )}
            </div>
          ))}
          {showLoader && (
            <LastAIMessage message={message} showLoader={showLoader} />
          )}
          <div ref={messagesEndRef} />
        </MessageList>

        <footer className="pb-4 rounded-3xl drop-shadow-md">
          <form onSubmit={handleSubmit(onSubmit)} ref={formRef} className="">
            <Card className="max-w-[60%] mx-auto bg-zinc-100 border-0 rounded-none rounded-b-lg">
              <div className="flex flex-col p-2 gap-2">
                {files.length > 0 && (
                  <div className="flex flex-wrap gap-2">
                    {files.map((file) => (
                      <div
                        key={file.id}
                        className="flex items-center bg-zinc-400 text-zinc-800 rounded px-2 py-1"
                      >
                        {file.type === "image" ? (
                          <Image className="h-4 w-4 mr-2" />
                        ) : (
                          <File className="h-4 w-4 mr-2" />
                        )}
                        <span className="text-sm truncate max-w-[150px]">
                          {file.name}
                        </span>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="ml-1 h-5 w-5 text-zinc-400 hover:text-zinc-100"
                          onClick={() => handleRemoveFile(file.id)}
                        >
                          <X className="h-3 w-3" />
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
                <div className="flex p-2 gap-2">
                  <textarea
                    ref={(e) => {
                      ref(e);
                      textareaRef.current = e; // you can still assign to ref
                    }}
                    className="flex-1 bg-transparent text-black placeholder-zinc-600 outline-none resize-none overflow-y-auto "
                    onKeyDown={onTextareaKeydown}
                    onInput={handleInputChange}
                    placeholder="Message UB AI"
                    disabled={showLoader}
                    rows={1}
                    {...rest}
                    style={{ minHeight: "24px", maxHeight: "240px" }} // 24px * 6 rows
                  />
                </div>
                <Input
                  ref={(e) => {
                    fileRef(e);
                    fileInputRef.current = e; // you can still assign to ref
                  }}
                  multiple
                  {...fileRest}
                  className="text-sm text-grey-500 w-full h-24
              file:mr-5 file:py-2 file:px-6
              file:rounded-full file:border-0
              file:text-sm file:font-medium
              file:bg-blue-50 file:text-blue-700
              hover:file:cursor-pointer hover:file:bg-amber-50
              border-none
              hover:file:text-amber-700 hidden"
                  name="file"
                  type="file"
                  placeholder={"TEXT"}
                  onChange={onFileChange}
                />

                <div className="flex justify-between">
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="ghost"
                        size="icon"
                        className="text-black  hover:bg-zinc-500 rounded-md transition-colors"
                      >
                        <Plus className="h-5 w-5" />
                        <span className="sr-only">Add file or image</span>
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      align="start"
                      className="w-56 bg-zinc-800 text-zinc-100 "
                    >
                      <DropdownMenuItem
                        onClick={() => fileInputRef.current.click()}
                        className="hover:bg-zinc-700 focus:bg-zinc-700"
                      >
                        <File className="mr-2 h-4 w-4" />
                        <span>Upload File</span>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                  {showLoader ? (
                    <Button
                      variant="ghost"
                      size="icon"
                      className="text-zinc-400 hover:text-black hover:bg-white/10 rounded-md transition-colors"
                      onClick={onCancel}
                    >
                      <CirclePause className="h-5 w-5" />
                    </Button>
                  ) : (
                    <Button
                      variant="ghost"
                      size="icon"
                      className="text-zinc-400 hover:text-black hover:bg-white/10 rounded-md transition-colors"
                      disabled={message.length <= 0}
                    >
                      <Send className="h-5 w-5" />
                      <span className="sr-only">Send message</span>
                    </Button>
                  )}
                </div>
              </div>
            </Card>
          </form>
        </footer>
      </div>
    </>
  );
}
